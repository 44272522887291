import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";

export default function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: (theme) => (theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[800]),
        p: 6,
      }}
      component="footer"
    >
      <Container maxWidth="sm" sx={{ display: "flex" }}>
        <Container maxWidth="sm" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
          <Typography variant="body2" color="text.secondary">
            <Link to="/aviso-legal">Aviso Legal</Link>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <Link to="/politica-privacidad">Politica de privacidad</Link>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {"© "}
            {new Date().getFullYear()} Iñaki Kanpion
            {"."}
          </Typography>
        </Container>
        <Container maxWidth="sm" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <a href="https://es-la.facebook.com/people/Deshollinador-I%C3%B1aki-Kanpion/100054371614319/" target="_blank" rel="noreferrer">
            <FacebookIcon color="primary" sx={{ mx: 1 }} />
          </a>

          <a href="https://www.instagram.com/inaki_kanpion/" target="_blank" rel="noreferrer">
            <InstagramIcon color="primary" sx={{ mx: 1 }} />
          </a>
        </Container>
      </Container>
    </Box>
  );
}
