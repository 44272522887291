import React from "react";
import ResponsiveAppBar from "./ResponsiveAppBar";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import IndexPage from "./IndexPage";
import AvisoLegalPage from "./AvisoLegalPage";
import PoliticaDePrivacidadPage from "./PoliticaDePrivacidadPage";
import Footer from "./Footer";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { orange } from "@mui/material/colors";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const theme = createTheme({
  status: {
    danger: orange[500],
  },
  palette: {
    primary: {
      main: "#333",
    },
    secondary: {
      main: "#999",
    },
    action: {
      active: "#e74c3c",
      hover: "#AAA",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <div className="App">
          <ResponsiveAppBar></ResponsiveAppBar>

          <Routes>
            <Route path="/" element={<IndexPage />} />
            <Route path="/aviso-legal" element={<AvisoLegalPage />} />
            <Route path="/politica-privacidad" element={<PoliticaDePrivacidadPage />} />
          </Routes>

          <Footer></Footer>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
