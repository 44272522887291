import React from "react";
import "./css/landing.css";
import { Box, Container, Divider, ImageList, ImageListItem, Typography } from "@mui/material";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { useSpring, animated } from "@react-spring/web";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import { HashLink } from "react-router-hash-link";

const GoDownArrow = () => {
  const bounceBig = useSpring({
    y: 0,
    from: { y: -30 },
    loop: { reverse: true },
  });
  const bounceSmall = useSpring({
    y: 0,
    from: { y: -15 },
    loop: { reverse: true },
  });

  return (
    <Box
      className="floatingBtnLayout"
      sx={{
        alignSelf: "center",
        justifySelf: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <HashLink to="#contacto" smooth>
        <Typography
          variant="h6"
          className="textIcon"
          color="white"
          sx={{ letterSpacing: ".20rem", textAlign: "center", pb: 2, fontSize: "Min(5vw,20pt)" }}
          display={{ xs: "none", sm: "none", md: "flex" }}
        >
          {" "}
          Contacto
        </Typography>
      </HashLink>

      <HashLink to="#contacto" smooth>
        <Typography
          variant="h6"
          className="textIcon"
          color="white"
          sx={{ letterSpacing: ".20rem", textAlign: "center", pb: 1, fontSize: "Min(2vh,20pt)" }}
          display={{ xs: "flex", sm: "flex", md: "none" }}
        >
          {" "}
          Contacto
        </Typography>
      </HashLink>
      <Box display={{ xs: "flex", sm: "flex", md: "none" }}>
        <animated.div
          style={{
            ...bounceSmall,
          }}
        >
          <HashLink to="#contacto" smooth>
            <KeyboardDoubleArrowDownIcon sx={{ fontSize: 60 }} color="action" className="arrowIcon" />
          </HashLink>
        </animated.div>
      </Box>

      <Box display={{ xs: "none", sm: "none", md: "flex" }}>
        <animated.div
          style={{
            ...bounceBig,
          }}
        >
          <HashLink to="#contacto" smooth>
            <KeyboardDoubleArrowDownIcon sx={{ fontSize: 130 }} color="action" className="arrowIcon" />
          </HashLink>
        </animated.div>
      </Box>
    </Box>
  );
};

export default function IndexPage() {
  const [scrollPosition, setScrollPosition] = React.useState(0);

  const handleScroll = () => {
    const position =
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    setScrollPosition(position);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="balls-container" style={{ opacity: 1 - (2 * scrollPosition) / window.innerHeight }}>
        <div className="ball ball1"></div>
        <div className="ball ball2"></div>
        <div className="ball ball3"></div>
        <div className="ball ball4"></div>
        <div className="ball ball5"></div>
        <div className="ball ball6"></div>
        <div className="ball ball7"></div>
        <div className="ball ball8"></div>
        <div className="ball ball9"></div>
        <div className="ball ball10"></div>
        <div className="ball ball11"></div>
      </div>
      <div className="bg"></div>

      <GoDownArrow />
      <Container id="landing">
        <div className="landingContainer">
          <Box
            display={{
              xs: "flex",
              sm: "none",
              md: "none",
              lg: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                mt: 5,
              }}
            >
              <Box>
                <LocalPhoneIcon sx={{ alignSelf: "center", mx: 0.5, fontSize: 50 }} color="action" className="arrowIcon" />{" "}
              </Box>

              <Typography
                variant="h5"
                component="h2"
                className="phone xs"
                sx={{ letterSpacing: ".20rem", display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                {"606979891"}
              </Typography>
              <Box>
                <WhatsAppIcon sx={{ fontSize: 50, alignSelf: "center", mx: 0.5, color: "#25D366" }} className="arrowIcon" />
              </Box>
            </Box>
          </Box>

          <Box
            display={{
              xs: "none",
              sm: "flex",
              md: "none",
              lg: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{}}>
              <Typography
                variant="h5"
                component="h2"
                className="phone sm"
                sx={{ letterSpacing: ".20rem", mt: 5, display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                <Box sx={{ display: "flex", flexDirection: "row", mx: 1 }}>
                  <LocalPhoneIcon sx={{ alignSelf: "center", mx: 1, fontSize: 50 }} color="action" className="arrowIcon" />{" "}
                  <WhatsAppIcon sx={{ fontSize: 50, alignSelf: "center", mx: 1, color: "#25D366" }} className="arrowIcon" />
                </Box>
                {"606979891"}
              </Typography>
            </div>
          </Box>

          <Box display={{ xs: "none", sm: "none", md: "none", lg: "flex" }} className="logoContainer lg xl">
            <img loading="lazy" src="logo_w.png" alt="logo" className="logo lg" width="70%" />
          </Box>
          <Box display={{ xs: "none", sm: "none", md: "none", lg: "none" }} className="logoContainer md">
            <img loading="lazy" src="logo_w.png" alt="logo" className="logo md" width="50%" />
          </Box>
          <Box
            display={{ xs: "none", sm: "flex", md: "flex", lg: "none" }}
            // sx={{ alignSelf: "flex-start", flexDirection: "column", flexWrap: "wrap" }}
            className="logoContainer sm"
          >
            <img loading="lazy" src="logo_w.png" alt="logo" className="logo sm" />
          </Box>

          <Box
            display={{ xs: "flex", sm: "none", md: "none", lg: "none" }}
            // sx={{ alignSelf: "flex-start", flexDirection: "column", flexWrap: "wrap" }}
            className="logoContainer xs"
          >
            <img loading="lazy" src="logo_w.png" alt="logo" className="logo xs" />
          </Box>

          {/* <Box display={{ md: "none", lg: "flex" }} sx={{}}>
            AAAAAAAAAAAAAAA
          </Box> */}
          <Box display={{ xs: "none", sm: "none", md: "none", xl: "flex" }} sx={{ flexDirection: "column" }}>
            <div>
              <Typography variant="h2" component="h2" className="title1 xl" sx={{ letterSpacing: ".35rem" }}>
                {"Iñaki Kanpion".toUpperCase()}
              </Typography>
            </div>
            <div>
              <Typography variant="h2" component="h2" className="title2 xl" sx={{ letterSpacing: ".030rem" }}>
                {"Deshollinador".toUpperCase()}
              </Typography>
            </div>
            <div>
              <Typography variant="h5" component="h2" className="subtitle xl" sx={{ letterSpacing: ".20rem" }}>
                {"Limpieza y mantenimiento de chimeneas"}
              </Typography>
            </div>
            <div style={{}}>
              <Typography
                variant="h5"
                component="h2"
                className="phone xl"
                sx={{ letterSpacing: ".20rem", display: "flex", alignItems: "center", justifyContent: "center", mt: 2 }}
              >
                <Box sx={{ display: "flex", flexDirection: "row", mx: 1 }}>
                  <LocalPhoneIcon sx={{ alignSelf: "center", mx: 1, fontSize: 50 }} color="action" className="arrowIcon" />{" "}
                  <WhatsAppIcon sx={{ fontSize: 50, alignSelf: "center", mx: 1, color: "#25D366" }} className="arrowIcon" />
                </Box>
                {"606979891"}
              </Typography>
            </div>
          </Box>
          <Box display={{ xs: "none", sm: "none", md: "none", lg: "flex", xl: "none" }} sx={{ flexDirection: "column" }}>
            <div>
              <Typography variant="h2" component="h2" className="title1 lg" sx={{ letterSpacing: ".35rem" }}>
                {"Iñaki Kanpion".toUpperCase()}
              </Typography>
            </div>
            <div>
              <Typography variant="h2" component="h2" className="title2 lg" sx={{ letterSpacing: ".030rem" }}>
                {"Deshollinador".toUpperCase()}
              </Typography>
            </div>
            <div>
              <Typography variant="h5" component="h2" className="subtitle lg" sx={{ letterSpacing: ".20rem" }}>
                {"Limpieza y mantenimiento de chimeneas"}
              </Typography>
            </div>
            <div style={{}}>
              <Typography
                variant="h5"
                component="h2"
                className="phone lg"
                sx={{ letterSpacing: ".20rem", display: "flex", alignItems: "center", justifyContent: "center", mt: 2 }}
              >
                <Box sx={{ display: "flex", flexDirection: "row", mx: 1 }}>
                  <LocalPhoneIcon sx={{ alignSelf: "center", mx: 1, fontSize: 50 }} color="action" className="arrowIcon" />{" "}
                  <WhatsAppIcon sx={{ fontSize: 50, alignSelf: "center", mx: 1, color: "#25D366" }} className="arrowIcon" />
                </Box>
                {"606979891"}
              </Typography>
            </div>
          </Box>

          <Box display={{ xs: "none", sm: "flex", md: "flex", lg: "none" }} sx={{ flexDirection: "column" }}>
            <div>
              <Typography variant="h2" component="h2" className="title1 sm" sx={{ letterSpacing: ".35rem" }}>
                {"Iñaki Kanpion".toUpperCase()}
              </Typography>
            </div>
            <div>
              <Typography variant="h2" component="h2" className="title2 sm" sx={{ letterSpacing: ".030rem" }}>
                {"Deshollinador".toUpperCase()}
              </Typography>
            </div>
            <div>
              <Typography variant="h5" component="h2" className="subtitle sm" sx={{ letterSpacing: ".20rem" }}>
                {"Limpieza y mantenimiento de chimeneas"}
              </Typography>
            </div>
            <Box display={{ xs: "none", sm: "none", md: "flex", lg: "none", alignItems: "center", justifyContent: "center" }}>
              <Typography
                variant="h5"
                component="h2"
                className="phone sm"
                sx={{ letterSpacing: ".20rem", mt: 2, display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                <Box sx={{ display: "flex", flexDirection: "row", mx: 1 }}>
                  <LocalPhoneIcon sx={{ alignSelf: "center", mx: 1, fontSize: 50 }} color="action" className="arrowIcon" />{" "}
                  <WhatsAppIcon sx={{ fontSize: 50, alignSelf: "center", mx: 1, color: "#25D366" }} className="arrowIcon" />
                </Box>
                {"606979891"}
              </Typography>
            </Box>
          </Box>
          <Box display={{ xs: "flex", sm: "none", md: "none" }} sx={{ flexDirection: "column" }}>
            <div>
              <Typography variant="h2" component="h2" className="title1 xs" sx={{ letterSpacing: ".35rem" }}>
                {"Iñaki Kanpion".toUpperCase()}
              </Typography>
            </div>
            <div>
              <Typography variant="h2" component="h2" className="title2 xs" sx={{ letterSpacing: ".030rem" }}>
                {"Deshollinador".toUpperCase()}
              </Typography>
            </div>
            <div>
              <Typography variant="h5" component="h2" className="subtitle xs" sx={{ letterSpacing: ".20rem" }}>
                {"Limpieza y mantenimiento de chimeneas"}
              </Typography>
            </div>
          </Box>
          {/* <Box display={{ xs: "flex", sm: "none", md: "none" }} sx={{ flexDirection: "column" }} className="logoContainer sm"> */}
        </div>
      </Container>

      {/* ////////////// CONTACTO //////////////// */}

      <Container className="asadsadasdsad contacto" id="contacto">
        {/* <h1>Contacto</h1>
        <h2>Telefono</h2>
        <h2>Email</h2> */}
        <div
          className="balls-container-2"
          style={{
            opacity: 2 - scrollPosition / window.innerHeight - (scrollPosition - window.innerHeight) / window.innerHeight,
          }}
        >
          {/* <div className="ball ball1"></div>
          <div className="ball ball2"></div>
          <div className="ball ball3"></div>
          <div className="ball ball4"></div>
          <div className="ball ball5"></div>
          <div className="ball ball6"></div> */}
        </div>

        <Box style={{ display: "flex", flexDirection: "column", flex: "1", minHeight: "100vh" }}>
          <Typography variant="h5" component="h2" className="subtitle xs" sx={{ letterSpacing: ".20rem" }}>
            {"Contacto"}
          </Typography>
          <Divider light />
          <br />

          <Box
            style={{
              flex: 1,
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "row",
            }}
            className="contactInfoLayout"
            display={{ xs: "flex", sm: "flex", md: "none" }}
          >
            <Box sx={{}} pt={2} mb={1}>
              <Typography variant="h5" component="h2" className="subtitle xs" sx={{ letterSpacing: ".05rem" }} mt={10}>
                {"Solicite un presupuesto sin compromiso:"}
              </Typography>{" "}
              <Typography
                variant="h6"
                component="h2"
                className="phone xl"
                sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", mt: 2 }}
              >
                <LocalPhoneIcon sx={{ fontSize: "inherit", alignSelf: "center", mx: 1 }} color="action" className="arrowIcon" />{" "}
                <WhatsAppIcon sx={{ fontSize: "inherit", alignSelf: "center", mx: 1, color: "#25D366" }} className="arrowIcon" />
                <small style={{ marginRight: "10px" }}>Teléfono:</small>
                <Box display={{ xs: "none", sm: "none", md: "flex" }}>{" 606 979 891"}</Box>
              </Typography>
              <Box display={{ xs: "flex", sm: "flex", md: "none" }} sx={{ justifyContent: "center" }}>
                <Typography
                  variant="h6"
                  component="h2"
                  className="phone xl"
                  sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", mt: 2, fontSize: 20 }}
                >
                  {" 606 979 891"}
                </Typography>
              </Box>
              <Typography
                variant="h6"
                component="h2"
                className="phone xl"
                sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", mt: 2, flexWrap: "wrap" }}
              >
                <EmailIcon sx={{ fontSize: "inherit", alignSelf: "center", mx: 1 }} color="action" className="arrowIcon" />{" "}
                <small style={{ marginRight: "10px" }}>Correo electrónico:</small>
              </Typography>
              <Box display={{ xs: "flex", sm: "flex", md: "none" }} sx={{ justifyContent: "center" }} mb={30}>
                <Typography
                  variant="h6"
                  component="h2"
                  className="phone xl"
                  sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", mt: 2, fontSize: 20 }}
                >
                  {" elcorreodelkanpi@gmail.com"}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ marginBottom: "" }} mt={1}>
              <Typography variant="h5" component="h2" className="subtitle xs" sx={{ textAlign: "center" }} mb={2}>
                {"Tierra Estella (Navarra)"}
                <br />
                {"y alrededores"}
              </Typography>
              <img loading="lazy" src="mapa.png" alt="mapa" className="mapa xs" />
            </Box>
          </Box>
          <Box
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
            className="contactInfoLayout"
            display={{ xs: "none", sm: "none", md: "flex" }}
          >
            <Box sx={{}} p={2}>
              <Typography variant="h5" component="h2" className="subtitle xs" sx={{ letterSpacing: ".05rem" }}>
                {"Solicite un presupuesto sin compromiso:"}
              </Typography>{" "}
              <Typography
                variant="h6"
                component="h3"
                className="phone xl"
                sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", mt: 2 }}
              >
                <LocalPhoneIcon sx={{ fontSize: "inherit", alignSelf: "center", mx: 1 }} color="action" className="arrowIcon" />{" "}
                <WhatsAppIcon sx={{ fontSize: "inherit", alignSelf: "center", mx: 1, color: "#25D366" }} className="arrowIcon" />
                {"Teléfono: 606 979 891"}
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                className="phone xl"
                sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", mt: 2 }}
              >
                <EmailIcon sx={{ fontSize: "inherit", alignSelf: "center", mx: 1 }} color="action" className="arrowIcon" />{" "}
                {"Correo electrónico: elcorreodelkanpi@gmail.com"}
              </Typography>
            </Box>

            <Box>
              <Typography variant="h5" component="h2" className="subtitle xs" sx={{ textAlign: "center" }} mb={2}>
                {"Tierra Estella (Navarra)"}
                <br />
                {"y alrededores"}
              </Typography>
              <img loading="lazy" src="mapa.png" alt="mapa" className="mapa" />
            </Box>
          </Box>
        </Box>
      </Container>
      {/* ////////////// SERVICIOS //////////////// */}
      <Container className="asadsadasdsad servicios" id="servicios">
        <Box style={{ height: "100%", display: "flex", width: "100%", flexDirection: "column", minHeight: "100vh" }}>
          <Typography variant="h5" component="h2" className="subtitle xs" sx={{ letterSpacing: ".20rem" }}>
            {"Servicios"}
          </Typography>
          <Divider light />
          <br />
          <Box
            style={{
              flex: 1,
              alignItems: "flex-end",
              justifyContent: "center",
              // flexDirection: "row",
              display: "flex",
            }}
            my={2}
          >
            <Typography variant="body1" component="h2" className="subtitle xs" sx={{ letterSpacing: ".20rem", width: "80%", fontSize: 20 }}>
              Limpiamos desde el tejado <b>sin ensuciar la casa. </b>Garantia de trabajo limpio, rapido y con todos los requisitos de
              seguridad. Para viviendas particulares y comunidades.
            </Typography>
          </Box>

          <Box
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              display: "flex",
            }}
            // display={{ xs: "none", sm: "flex", md: "flex" }}
            className="serviciosLayout"
          >
            <ul style={{ width: "70%" }}>
              <li>
                <Typography variant="body1" component="p" className="subtitle xs" sx={{ letterSpacing: ".20rem", fontSize: 20 }}>
                  {"Limpieza y mantenimiento de chimeneas de leña, pellet y gasoil"}
                </Typography>
                <br />
              </li>
              <li>
                {" "}
                <Typography variant="body1" component="p" className="subtitle xs" sx={{ letterSpacing: ".20rem", fontSize: 20 }}>
                  {"Limpieza de estufas y fogones"}
                </Typography>
                <br />
              </li>
              <li>
                {" "}
                <Typography variant="body1" component="p" className="subtitle xs" sx={{ letterSpacing: ".20rem", fontSize: 20 }}>
                  {"Evacuación de hollín mediante aspiración"}
                </Typography>
                <br />
              </li>
              <li>
                {" "}
                <Typography variant="body1" component="p" className="subtitle xs" sx={{ letterSpacing: ".20rem", fontSize: 20 }}>
                  {"Limpieza de canalones y tejados"}
                </Typography>
              </li>
              <br />
              <li>
                {" "}
                <Typography variant="body1" component="p" className="subtitle xs" sx={{ letterSpacing: ".20rem", fontSize: 20 }}>
                  {"Inspección con cámara para detección de todo tipo de problemas"}
                </Typography>
              </li>
              <br />
              <li>
                {" "}
                <Typography variant="body1" component="p" className="subtitle xs" sx={{ letterSpacing: ".20rem", fontSize: 20 }}>
                  {"Instalación de malla anti pájaros"}
                </Typography>
              </li>
            </ul>

            <Box className="imgServContainer">
              <img loading="lazy" src="chim1.jpg" alt="logo" className="imgServ" />
              <img loading="lazy" src="chim2.jpg" alt="logo" className="imgServ" />
              <img loading="lazy" src="chim3.jpg" alt="logo" className="imgServ" />
            </Box>
          </Box>
        </Box>
      </Container>
      <Container className="asadsadasdsad" id="videos">
        <Box style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", minHeight: "100vh" }}>
          <Typography variant="h5" component="h2" className="subtitle xs" sx={{ letterSpacing: ".20rem" }}>
            {"Videos"}
          </Typography>
          <Divider light />
          <br />
          <div style={{ height: "100px" }}></div>
          <Box className="videoGalery">
            <Box className="videoItem">
              <a
                href="https://www.rtve.es/play/videos/aqui-la-tierra/adopta-marta-alturas/3816854/?media=tve"
                target="_blank"
                rel="noreferrer"
                className="linkImg"
              >
                <img loading="lazy" src="rtveLinkImg.png" alt="" className="videoLink rtve xl" />
              </a>
              <a
                href="https://www.rtve.es/play/videos/aqui-la-tierra/adopta-marta-alturas/3816854/?media=tve"
                target="_blank"
                rel="noreferrer"
                className="linkImg"
              >
                <img loading="lazy" src="rtveLinkImg.png" alt="" className="videoLink rtve lg" />
              </a>
              <Typography variant="h5" component="h2" className="subtitle xs" sx={{ letterSpacing: ".20rem" }}>
                {"En las alturas"}
              </Typography>
              <Typography variant="body1" component="h2" className="subtitle xs" sx={{ letterSpacing: ".20rem" }}>
                {"RTVE"}
              </Typography>
              <br />
            </Box>

            <br />
          </Box>

          <Box className="videoGalery">
            <Box className="videoItem">
              <iframe
                src="https://www.eitb.eus/es/get/multimedia/screen/id/3939640/tipo/videos/television/mas-de-tres-millones/"
                style={{ border: 0 }}
                scrolling="no"
                className="videoLink etb"
                title={"Iñaki"}
              />
              <Typography
                variant="h6"
                component="h2"
                className="subtitle xs"
                sx={{ letterSpacing: ".20rem", maxWidth: "200px", textAlign: "center" }}
              >
                {"Iñaki"}
              </Typography>
              <Typography
                variant="body2"
                component="h2"
                className="subtitle xs"
                sx={{ letterSpacing: ".20rem", maxWidth: "300px", textAlign: "center" }}
              >
                {"El deshollinador con chistera"}
              </Typography>
            </Box>
            <Box className="videoItem">
              <iframe
                src="https://www.eitb.eus/es/get/multimedia/screen/id/4518289/tipo/videos/television/video-inaki-kanpion-habla-su-experiencia-deshollinador/"
                style={{ border: 0 }}
                scrolling="no"
                className="videoLink etb"
                title={"Ahora"}
              />
              <Typography variant="h6" component="h2" className="subtitle xs" sx={{ letterSpacing: ".20rem" }}>
                {"Ahora"}
              </Typography>
              <Typography
                variant="body2"
                component="h2"
                className="subtitle xs"
                sx={{ letterSpacing: ".20rem", maxWidth: "300px", textAlign: "center" }}
              >
                {"Profesionales del riesgo"}
              </Typography>
            </Box>
            <Box className="videoItem">
              <iframe
                src="https://www.eitb.eus/es/get/multimedia/screen/id/3618632/tipo/videos/television/video-el-oficio-deshollinador-peligro-extincion/"
                style={{ border: 0 }}
                scrolling="no"
                className="videoLink etb"
                title={"Deshollinadores"}
              />
              <Typography variant="h6" component="h2" className="subtitle xs" sx={{ letterSpacing: ".20rem" }}>
                {"Deshollinadores"}
              </Typography>
              <Typography
                variant="body2"
                component="h2"
                className="subtitle xs"
                sx={{ letterSpacing: ".20rem", maxWidth: "300px", textAlign: "center" }}
              >
                {"El oficio en peligro de extinción"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
      <Container className="asadsadasdsad" id="fotos">
        <Box style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", minHeight: "100vh" }}>
          <Typography variant="h5" component="h2" className="subtitle xs" sx={{ letterSpacing: ".20rem" }}>
            {"Fotos"}
          </Typography>
          <Divider light />
          <br />
          <ImageList sx={{}} variant="quilted" cols={4} rowHeight={221}>
            <ImageListItem cols={4} rows={3}>
              <img loading="lazy" src="foto1.jpg" alt="foto1" />
            </ImageListItem>
            <ImageListItem cols={4} rows={3}>
              <img loading="lazy" src="foto3.jpg" alt="foto2" />
            </ImageListItem>
            <ImageListItem cols={4} rows={4}>
              <img loading="lazy" src="foto2.jpg" alt="foto3" className="right" />
            </ImageListItem>
          </ImageList>
        </Box>
      </Container>

      {/*   <Container>DONDE</Container> */}

      {/* 
      
        DONDE ESTA
        QUE PUEDE HACER
        CONTACTO 
        VIDEOS
        FOTOS
        REDES
      
      */}
    </>

    // <div>
    //   <div>
    //     <div className="embed-layout">
    //       <iframe className="youtube-embed" title={"video"} src="https://www.youtube.com/embed/Da2rUIthdbE"></iframe>
    //     </div>
    //   </div>
    // </div>
  );
}
