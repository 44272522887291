import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";

const pages = ["contacto", "servicios", "videos", "fotos"];
// const settings = ["Contacto", "Servicios", "Videos", "Fotos"];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  // const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  // const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  const location = useLocation();
  console.log(location.pathname);

  return (
    <AppBar position="sticky" className="header" sx={{ mt: 0 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <FireplaceIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            <HashLink smooth to="/#top" style={{ display: "flex", alignItems: "center" }}>
              <img src="logo_w.png" alt="logo" className="logo" width="5%" />
              <Typography
                variant="h6"
                noWrap
                sx={{
                  mr: 2,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "white",
                  textDecoration: "none",
                  ml: 2,
                }}
              >
                KANPION
              </Typography>
            </HashLink>
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                // MENU
                <MenuItem
                  key={page}
                  onClick={() => {
                    handleCloseNavMenu();
                  }}
                >
                  <HashLink
                    // className="hashLink"
                    to={`/#${page}`}
                    smooth
                    style={{ color: "inherit", margin: "5px 20px", fontWeight: 400, fontSize: "12pt" }}
                  >
                    {page[0].toUpperCase() + page.slice(1, page.length)}
                  </HashLink>
                  {/* <Typography textAlign="center">{page}</Typography> */}
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, alignItems: "center", justifyContent: "center" }}>
            <img src="logo_w.png" alt="logo" className="logo" width="5%" />
            <HashLink smooth to="/#top">
              <Typography
                variant="h5"
                noWrap
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  textDecoration: "none",
                  ml: 1,
                }}
                color={"white"}
              >
                KANPION
              </Typography>
            </HashLink>
          </Box>

          <Box sx={{ justifyContent: "flex-end", flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {" "}
            {/*#, justifySelf:'flex-end'  */}
            {pages.map((page) => (
              <Box
                key={page}
                sx={{ my: 2, color: "white", display: "block" }}
                onClick={() => {
                  handleCloseNavMenu();
                }}
              >
                <HashLink className="hashLink" to={`/#${page}`} smooth style={{ color: "white", fontWeight: 500 }}>
                  <Typography
                    variant="h6"
                    noWrap
                    sx={{
                      ml: 3,
                      flexGrow: 1,
                      fontFamily: "monospace",
                      fontWeight: 300,
                      letterSpacing: ".2rem",
                      textDecoration: "none",
                      fontSize: "12pt",
                    }}
                  >
                    {page.toUpperCase()}
                  </Typography>
                </HashLink>
              </Box>
            ))}
          </Box>

          {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
